import React from 'react'
import { Modal } from 'react-bootstrap'
import RecruitmentLandingPageForm, {
  ILandingPageColours,
} from './creation/RecruitmentLandingPageForm'

type Props = {
  submitForm: (colors: ILandingPageColours) => Promise<void>
  campaignId: string
  initColors: () => ILandingPageColours
  showModalConfirm: boolean
  setShowModalConfirm: (show: boolean) => void
}

const RecruitmentLandingpageEditModal: React.FC<Props> = ({
  submitForm,
  campaignId,
  initColors,
  showModalConfirm,
  setShowModalConfirm,
}) => {
  return (
    <Modal
      show={showModalConfirm}
      onHide={() => setShowModalConfirm(false)}
      size='lg'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className='modal-title'>Rediger facebook-preview</h5>
        </Modal.Title>
      </Modal.Header>
      <div className='modal-body'>
        <RecruitmentLandingPageForm
          submitForm={submitForm}
          campaignId={campaignId}
          initColors={initColors}
          submitText='Lagre'
        />
      </div>
    </Modal>
  )
}

export default RecruitmentLandingpageEditModal
