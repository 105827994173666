import clsx from 'clsx'
import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import * as Yup from 'yup'
import LoadingContext from '../../../../../_metronic/layout/core/Loading'
import { useMainContext } from '../../../../../context'
import { useAuth } from '../../../../modules/auth'
import { handleGetRequest } from '../../../../services'
import { ICampaignProps } from './CampaignCreationModal'

interface CampaignInfoFormProps {
  initialValues: () => ICampaignProps
  submitCallback: (values: ICampaignProps) => void
}

const CampaignInfoForm: React.FC<CampaignInfoFormProps> = ({ initialValues, submitCallback }) => {
  const campaignSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string(),
    level: Yup.string(),
    unit: Yup.string(),
    goal: Yup.string(),
  })

  const { setLoading } = useContext(LoadingContext)
  const initialFormValues = useRef(initialValues())
  const [level] = useState(initialFormValues.current.level || 'organisation')
  const [goals, setGoals] = useState<any>([])
  const { companyofPortal } = useMainContext()
  const { currentUser } = useAuth()

  const isOnSportLevel = () => !!companyofPortal?.company_id

  const getGoalsByOrg = async () => {
    const sportId = isOnSportLevel()
      ? [companyofPortal?.company_id]
      : currentUser?.user?.sports_list?.map((item: any) => item._id)

    const params = {
      organisationId: currentUser?.user?.organisation_id?._id,
      sportId,
      limit: 1000,
      page: 1,
    }
    const res = await handleGetRequest('/goal/by_organisation', { params })(setLoading)
    setGoals(res.data)
  }

  useEffect(() => {
    getGoalsByOrg()
  }, [companyofPortal?.company_id, currentUser?.user?.organisation_id?._id])

  return (
    <Formik
      initialValues={initialFormValues.current}
      enableReinitialize={false}
      validationSchema={campaignSchema}
      onSubmit={(values) => {
        const returnValues: ICampaignProps = { ...values }
        if (level === 'goal') {
          const unit = goals.find(
            (goal: any) => goal._id === values.goal
          )?.organisation_sports_category_id
          returnValues.unit = unit
        } else if (level === 'unit') {
          returnValues.goal = undefined
        } else {
          returnValues.unit = undefined
          returnValues.goal = undefined
        }
        submitCallback(returnValues)
      }}
    >
      {({ errors, touched, values, submitForm }) => (
        <Form>
          <div>
            <span className='text-muted'>
              Her kan du opprette kampanjer for å enkelt få inn nye støttespillere. Kampanjene kan
              være knyttet til en organisasjon, et formål eller en spesifikk enhet.For hver kampanje
              setter du opp deltagere som skal verve nye støttespillere.
            </span>
          </div>
          <div className='row mb-3 mt-7'>
            <label className='col-lg-3 col-form-label fw-bold fs-6'>Navn:</label>
            <div className='col-lg-9 fv-row'>
              <Field
                style={{ borderRadius: '8px' }}
                type='text'
                name='name'
                className={clsx('form-control form-control-lg form-control-solid inputClass mb-2', {
                  'is-invalid': touched.name && errors.name,
                })}
                placeholder='Angi tittel på kampanje'
              />
              <span className='text-muted'>
                Gi kampanjen et navn som tydelig identifiserer kampanjen.
                <br />
                Denne vil bli vist i flyer og i appen.
              </span>
            </div>
          </div>
          <div className='row mb-3 mt-7'>
            <label className='col-lg-3 col-form-label fw-bold fs-6'>Beskrivelse:</label>
            <div className='col-lg-9 fv-row'>
              <Field
                as='textarea'
                name='description'
                rows={3}
                className={clsx('form-control form-control-lg form-control-solid mb-2', {
                  'is-invalid': touched.description && errors.description,
                })}
                placeholder='Skriv en beskrivelse av kampanjen'
              />
              <span className='text-muted'>
                Gi kampanjen en beskrivelse som tydelig forklarer kampanjen og formålet med den.
                <br /> Denne vil bli vist i appen.
              </span>
            </div>
          </div>
          {/*     <div className='row mb-3 mt-7'>
            <label className='col-lg-3 col-form-label fw-bold fs-6'>Nivå:</label>
            <div className='col-lg-9 fv-row'>
              <Field
                style={{ borderRadius: '8px', borderColor: '1px solid #C6E0EC' }}
                as='select'
                name='level'
                className={clsx('form-select form-select-lg form-select-solid', {
                  'is-invalid': touched.level && errors.level,
                })}
                onChange={(e: any) => {
                  setFieldValue('level', e.target.value)
                  setLevel(e.target.value)
                }}
              >
                <option value='organisation'>Organisasjon</option>
                <option value='unit'>Enhet</option>
                <option value='goal'>Formål</option>
              </Field>
            </div>
          </div> */}

          {(level === 'unit' || values.level === 'unit') && (
            <div className='row mb-3 mt-7'>
              <label className='col-lg-3 col-form-label fw-bold fs-6' />
              <div className='col-lg-9 fv-row'>
                <Field
                  style={{ borderRadius: '8px', borderColor: '1px solid #C6E0EC' }}
                  as='select'
                  name='unit'
                  className={clsx('form-select form-select-lg form-select-solid', {
                    'is-invalid': touched.unit && errors.unit,
                  })}
                >
                  <option value={undefined} />
                  {currentUser?.user?.sports_list.map((unit: any) => (
                    <option value={unit._id} key={unit._id}>
                      {unit.sports_category_name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          )}

          {level === 'goal' && (
            <div className='row mb-3 mt-7'>
              <label className='col-lg-3 col-form-label fw-bold fs-6' />
              <div className='col-lg-9 fv-row'>
                <Field
                  style={{ borderRadius: '8px', borderColor: '1px solid #C6E0EC' }}
                  as='select'
                  name='goal'
                  className={clsx('form-select form-select-lg form-select-solid', {
                    'is-invalid': touched.goal && errors.goal,
                  })}
                >
                  <option value={undefined} />
                  {goals?.map((goal: any) => (
                    <option value={goal._id} key={goal._id}>
                      {goal.title}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          )}

          <div className='d-flex justify-content-end mt-3'>
            <Button variant='primary' onClick={submitForm}>
              Lag kampanje
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default React.memo(CampaignInfoForm)
