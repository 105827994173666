import React from 'react'
import { Button } from 'react-bootstrap'
import { ColorPopoverPicker } from '../../../../components/ColorPopoverPicker'

type Props = {
  submitForm: (colors: ILandingPageColours) => Promise<void>
  campaignId: string
  submitText?: string
  initColors: () => ILandingPageColours
}

export interface ILandingPageColours {
  backgroundColour: string
  textColour: string
  elementBackgroundColour: string
  elementTextColour: string
  logoColour: string
}

const RecruitmentLandingPageForm: React.FC<Props> = ({
  submitForm,
  campaignId,
  initColors,
  submitText,
}) => {
  const [backgroundColour, setBackgroundColour] = React.useState(
    initColors().backgroundColour || ''
  )
  const [textColour, setTextColour] = React.useState(initColors().textColour || '')
  const [elementBackgroundColour, setElementBackgroundColour] = React.useState(
    initColors().elementBackgroundColour || ''
  )
  const [elementTextColour, setElementTextColour] = React.useState(
    initColors().elementTextColour || ''
  )
  const [logoColour, setLogoColour] = React.useState(initColors().logoColour || '')

  const isInTest = window.location.hostname === 'testorg.stotte.no'

  const urlEncode = (val: string) => {
    return encodeURIComponent(val)
  }
  return (
    <div>
      <span className='text-muted'>
        Dette er siden som vil bli sendt til alle epost-adressene du la inn i forrige steg, som de
        da skal ta i bruk sine linker fra når de skal jobbe med rekrutteringen. Du kan velge
        klubbfargene deres på landingssiden. Teksten som kommer med er beskrivelsen du la inn i
        starten av prosessen, og kan redigeres på et senere tidspunkt om du ønsker det.
      </span>
      <div
        className='mb-4 mt-4'
        style={{
          height: '50vh',
          width: '100%',
          border: 'none',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <div
          style={{
            transform: 'scale(0.7)',
            transformOrigin: 'top left',
            width: '142.85%', // 100% / 0.7 to compensate for scale
            height: '142.85%',
            position: 'absolute',
          }}
        >
          <iframe
            src={`https://finn.stotte.no/${campaignId}?backgroundColour=${urlEncode(backgroundColour)}&textColour=${urlEncode(textColour)}&elementBackgroundColour=${urlEncode(elementBackgroundColour)}&elementTextColour=${urlEncode(elementTextColour)}&logoColour=${urlEncode(logoColour)}&isTest=${isInTest}`}
            sandbox='allow-same-origin allow-scripts allow-popups allow-forms'
            style={{ height: '100%', width: '100%', border: 'none' }}
            title='Recruitment Landing Page Preview'
          />
        </div>
      </div>
      <h4 className=''>Farge</h4>
      <div className='fb-control-group'>
        <div>
          Bakgrunn
          <ColorPopoverPicker
            color={backgroundColour}
            onChange={setBackgroundColour}
            text={false}
          />
        </div>
        <div>
          Tekst
          <ColorPopoverPicker color={textColour} onChange={setTextColour} text />
        </div>
        <div>
          Deltagerbakgrunn
          <ColorPopoverPicker
            color={elementBackgroundColour}
            onChange={(val) => {
              setElementBackgroundColour(val)
            }}
            text={false}
          />
        </div>
        <div>
          Deltagertekst
          <ColorPopoverPicker
            color={elementTextColour}
            onChange={(val) => {
              setElementTextColour(val)
            }}
            text={false}
          />
        </div>
        <div>
          Logo
          <ColorPopoverPicker
            color={logoColour}
            onChange={(val) => {
              setLogoColour(val)
            }}
            text
          />
        </div>
      </div>
      <div className='d-flex justify-content-end mt-3'>
        <Button
          variant='primary'
          onClick={() => {
            submitForm({
              backgroundColour,
              textColour,
              elementBackgroundColour,
              elementTextColour,
              logoColour,
            })
          }}
        >
          {submitText || 'Gå videre'}
        </Button>
      </div>
    </div>
  )
}

export default RecruitmentLandingPageForm
