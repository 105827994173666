/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { IRecruiter } from '../../../campaign/CampaignTable'

interface RecruitmentSendFormProps {
  recruiters: IRecruiter[]
  submitForm: (recruiters: IRecruiter[]) => Promise<void>
}

const RecruitmentSendForm: React.FC<RecruitmentSendFormProps> = ({ recruiters, submitForm }) => {
  const [selectedRecruiters, setSelectedRecruiters] = useState<IRecruiter[]>(recruiters)
  const [showEmailPreviewModal, setShowEmailPreviewModal] = useState(false)

  // Toggle individual recruiter
  const toggleRecruiter = (recruiter: IRecruiter) => {
    setSelectedRecruiters((prevSelected) => {
      const newSelected = prevSelected.some((selected) => selected.email === recruiter.email)
        ? prevSelected.filter((selected) => selected.email !== recruiter.email)
        : [...prevSelected, recruiter]
      return newSelected
    })
  }

  // Toggle all recruiters
  const toggleAll = () => {
    if (selectedRecruiters.length === recruiters.length) {
      setSelectedRecruiters([])
    } else {
      setSelectedRecruiters(recruiters)
    }
  }

  return (
    <div>
      <div>
        <span className='text-muted'>
          Du er nå på siste steg i oppsettet. Les gjennom e-posten som sendes til adressene du
          lastet opp{' '}
          <a
            onClick={() => {
              setShowEmailPreviewModal(true)
            }}
            style={{
              cursor: 'pointer',
              color: 'rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))',
            }}
          >
            HER
          </a>
          .
          <br /> <br /> Foreldrekontakter videresender e-posten til sin gruppe, som starter
          rekrutteringsarbeidet.
          <br /> Enkeltpersoner begynner rekrutteringen umiddelbart.
          <br /> <br /> Når du har sendt e-posten, er din jobb å følge opp mottakerne gjennom
          rekrutteringsperioden.
        </span>
      </div>{' '}
      <div className='mb-10 mt-10'>
        <Form.Check
          type='checkbox'
          style={{ textAlign: 'left' }}
          label='Velg alle'
          checked={selectedRecruiters.length === recruiters.length}
          onChange={toggleAll}
        />
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        {recruiters.map((recruiter, index) => (
          <Form.Check
            key={index}
            type='checkbox'
            style={{ marginBottom: '10px', marginRight: '10px' }}
            label={`${recruiter.name} (${recruiter.email})`}
            checked={selectedRecruiters.some((selected) => selected.email === recruiter.email)}
            onChange={() => toggleRecruiter(recruiter)}
          />
        ))}
      </div>
      <div className='d-flex justify-content-end mt-3'>
        <Button variant='primary' onClick={() => submitForm(selectedRecruiters)}>
          {selectedRecruiters.length > 0 ? 'Send og gå til kampanje' : 'Gå til kampanje'}
        </Button>
      </div>
      <Modal show={showEmailPreviewModal} onHide={() => setShowEmailPreviewModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Forhåndsvisning av e-post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4'>
            <span className='text-muted'>
              Hei,
              <br />
              <br />
              Her finner du all nødvendig info til kampanjen!
              <br />
              <br />
              <b>
                Før du trykker på lenken nederst kommer en kjapp opskrift på hvordan du bruker siden
                du kommer til:{' '}
              </b>
              <br />
              <br />
              <b>1. Scroll ned</b> til du finner riktig sted å knytte deg til.
              <br />
              <b>2. Sett opp egen støtte:</b> Trykk "Støtt" for å sette opp egen støtte som da
              knyttes til ønsket sted.
              <br />
              <b>
                3. Del lenke i sosiale medier eller sms/messenger for å hjelpe til med rekruttering:
              </b>{' '}
              Kopier direktelenken ved å trykke på "Del", og legg den ut på dine sosiale medier. Lag
              gjerne en personlig tekst som pirrer dine venner og familie til å bli med. Bildet med
              referanse til riktig sted vil bli automatisk generert. Legg gjerne også til lenke til{' '}
              <a href='https://stotte.no/faq/' target='_blank'>
                Støtte
              </a>{' '}
              for utfyllende info om appen.
              <br />
              <b>4. Del QR-kode:</b> Klikk på symbol for QR-kode, og print/vis til dine venner og
              familie for scanning. De som benytter denne QR-koden vil også knyttes til samme sted
              som deg.
              <br />
              <br />
              <b>Her er linken for både oppsett av støtte og verving av venner/familie: </b>
              <br />
              <br />
              Lykke til med kampanjen!
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowEmailPreviewModal(false)}>
            Lukk
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default RecruitmentSendForm
