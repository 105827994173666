import React from 'react'
import { Modal } from 'react-bootstrap'
import RecruitmentFacebookForm, { IFacebookPreview } from './creation/RecruitmentFacebookForm'

type Props = {
  defaultValues?: () => IFacebookPreview
  showModalConfirm: boolean
  setShowModalConfirm: (show: boolean) => void
  handleUpdate: (
    { primaryColour, secondaryColour, textColour, header }: IFacebookPreview,
    useFacebookColor?: boolean
  ) => void
}

const RecruitmentFacebookEditModal: React.FC<Props> = ({
  defaultValues,
  showModalConfirm,
  setShowModalConfirm,
  handleUpdate,
}) => {
  return (
    <Modal
      show={showModalConfirm}
      onHide={() => setShowModalConfirm(false)}
      size='lg'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className='modal-title'>Rediger facebook-preview</h5>
        </Modal.Title>
      </Modal.Header>
      <div className='modal-body'>
        <RecruitmentFacebookForm
          defaultValues={defaultValues ? defaultValues() : undefined}
          submitText='Lagre'
          submitForm={(preview) => {
            handleUpdate(preview)
          }}
        />
      </div>
    </Modal>
  )
}

export default RecruitmentFacebookEditModal
