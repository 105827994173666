/* eslint-disable jsx-a11y/anchor-is-valid */
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import * as Yup from 'yup'

const RecruitmentShareInternallyForm = ({ submitCallback }) => {
  const [showTextModal, setShowTextModal] = React.useState(false)
  const campaignSchema = Yup.object().shape({
    accept: Yup.boolean().oneOf([true], 'Du må godta vilkårene for å fortsette'),
  })

  return (
    <>
      <span>
        Det er viktig å forberede seg godt. Lag markedsføringsmateriell med egen identitet, og bruk
        kreative hjelpemidler som video for å vise aktivitet og hvordan innsamlede midler skal
        brukes.
        <br />
        <br />
        Sørg for at tillitsvalgte og foreldrekontakter får informasjon om Støtte,
        rekrutteringskampanjen og forventningene til deres rolle. Deretter bør medlemmer/foreldre
        informeres om kampanjen og hva som forventes av dem. Du finner forslag til tekster{' '}
        <a
          onClick={() => {
            setShowTextModal(true)
          }}
          style={{
            cursor: 'pointer',
            color: 'rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1))',
          }}
        >
          HER
        </a>{' '}
        som kan tilpasses etter behov.
      </span>
      <Formik
        initialValues={{ accept: false }}
        enableReinitialize={false}
        validationSchema={campaignSchema}
        onSubmit={() => {
          submitCallback()
        }}
      >
        {({ values, submitForm }) => (
          <Form>
            <div
              className=' mb-3 mt-7'
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <label className='col-form-label fw-bold fs-6'>
                Jeg har informert både internt og eksternt{' '}
              </label>
              <div className='fv-row' style={{ marginLeft: '10px' }}>
                <Field
                  style={{ borderRadius: '8px' }}
                  type='checkbox'
                  name='accept'
                  className='form-check-input'
                />
              </div>
            </div>

            <div className='d-flex justify-content-end mt-3'>
              <Button variant='primary' disabled={!values.accept} onClick={submitForm}>
                Gå videre
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        show={showTextModal}
        size='xl'
        onHide={() => setShowTextModal(false)}
        contentClassName='large-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title>Tekster til deling </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-4' style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
            <span className='text-muted' style={{ flex: 1 }}>
              <b>Hei foreldre i....</b>
              <br />
              <br />
              Økonomi er ofte en utfordring i idrettslag, og vi i klubben jobber aktivt for å sikre
              gode rammer for lagene. Nå introduserer vi <b>Støtte-appen</b> som et verktøy for å
              samle inn midler til lagkassene.
              <br />
              <br />
              Støtte-appen fungerer slik: Hver gang man bruker en valgt konto, doneres et mikrobeløp
              (f.eks. 2 kr) automatisk til lagkassen. Hvis alle spillere rekrutterer noen
              støttespillere, kan det raskt bli betydelige beløp. Støtte-appen er utviklet lokalt,
              med støtte fra Norges Idrettsforbund, og brukes allerede av over 400 klubber.
              <br />
              <br />
              Pengene går via Vipps direkte til klubben og fordeles på lagene. Midlene kan brukes på
              det laget selv ønsker, som turneringer, utstyr eller sosiale arrangementer.
              <br />
              <br />
              Vi har et mål om at hver spiller rekrutterer minst én støttespiller. For eksempel kan
              25 støttespillere gi ca. 4 000 kr til lagkassen hver måned – nesten 50 000 kr i året!
              <br />
              <br />
              Vi oppfordrer dere som foreldre til å støtte laget deres nå, og dele informasjonen på
              sosiale medier for å rekruttere enda flere støttespillere til klubben. Mer informasjon
              kommer snart fra foreldrekontakten på deres lag.
              <br />
              <br />
              Tusen takk for hjelpen!
              <br />
              <br />
            </span>{' '}
            <span className='text-muted' style={{ flex: 1 }}>
              <b>Hei lagledere og foreldrekontakter i ....</b>
              <br />
              <br />I år vil vi ta i bruk <b>Støtte-appen</b> for å hjelpe lagene med å fylle
              lagkassene.
              <br />
              <br />
              <b>Hva er Støtte-appen?</b>
              <br />
              Dette er en innovativ løsning som gjør innsamling enkelt. Etter registrering gir man
              automatisk et valgfritt mikrobeløp (f.eks. 2 kr) til lagkassen hver gang man bruker en
              valgt konto. Hvis alle spillere rekrutterer noen støttespillere, kan dette gi
              betydelige beløp til laget over tid. Pengene går direkte via Vipps til klubben og
              registreres på laget, som kan bruke midlene til utstyr, turneringer eller sosiale
              aktiviteter.
              <br />
              <br />
              <b>Kampanjer i 2025</b>
              <br />
              <br />
              Vi planlegger 3–4 kampanjerunder i løpet av året:
              <br /> • Én i februar
              <br /> • Én på våren
              <br /> • Én tidlig på høsten (og muligens én sent på høsten).
              <br />
              <br />
              Hvert lag får tilgang til materiell for å rekruttere støttespillere. Administrasjonen
              vil oppdatere dere underveis og stille med premier for vinnerlagene i hver runde.
              <br />
              <br />
              <b>Vårt mål</b>
              <br />
              Vi ønsker at hver spiller rekrutterer minst én støttespiller per kampanjerunde. Klarer
              vi dette, vil lagkassene kunne få betydelige bidrag. Et lag med 25 støttespillere kan
              for eksempel samle inn ca. 4 000 kr per måned – over 50 000 kr i året!
              <br />
              <br />
              <b>Hvordan lykkes vi? </b>
              <br />
              For å nå målene trenger vi at dere som lagledere og foreldrekontakter følger opp
              spillere og foreldre med informasjon, engasjement og motivasjon.
              <br />
              <br />
              Vi anbefaler dere å lese mer om Støtte-appen, laste den ned, og sette opp støtte til
              laget deres allerede nå – slik at dere er godt forberedt.
              <br />
              <br />
              Tusen takk for innsatsen, og vi gleder oss til å starte dette spennende prosjektet
              sammen med dere!
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowTextModal(false)}>
            Lukk
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default React.memo(RecruitmentShareInternallyForm)
