import clsx from 'clsx'
import { Field, Form, Formik } from 'formik'
import React, { useRef } from 'react'
import { Button } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import * as Yup from 'yup'
import { ICampaignProps } from '../../../campaign/modal/creation/CampaignCreationModal'

interface RecruitmentInfoFormProps {
  initialValues: () => ICampaignProps
  submitCallback: (values: ICampaignProps) => void
}

const RecruitmentInfoForm: React.FC<RecruitmentInfoFormProps> = ({
  initialValues,
  submitCallback,
}) => {
  const campaignSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    description: Yup.string(),
    level: Yup.string(),
    unit: Yup.string(),
    goal: Yup.string(),
  })

  const initialFormValues = useRef(initialValues())

  return (
    <Formik
      initialValues={initialFormValues.current}
      enableReinitialize={false}
      validationSchema={campaignSchema}
      onSubmit={(values) => {
        const returnValues: ICampaignProps = { ...values }

        submitCallback(returnValues)
      }}
    >
      {({ errors, touched, submitForm }) => (
        <Form>
          <span>
            Under ser du automatisk generert forslag til tekst som vil komme på materialet du nå
            skal lage. Du kan redigere tekstene her og nå, eller på et senere tidspunkt om du ønsker
            det.
          </span>
          <div className='row mb-3 mt-7'>
            <label
              className='col-lg-2 col-form-label fw-bold fs-6'
              style={{ whiteSpace: 'nowrap' }}
            >
              Navn:
            </label>
            <div className='col-lg-8 fv-row'>
              <Field
                style={{ borderRadius: '8px' }}
                type='text'
                name='name'
                className={clsx('form-control form-control-lg form-control-solid inputClass mb-2', {
                  'is-invalid': touched.name && errors.name,
                })}
                placeholder='Angi tittel på kampanje'
              />
              <span className='text-muted'>Du kan redigere det senere om du ønsker</span>
            </div>
          </div>
          <div className='row mb-3 mt-7'>
            <label
              className='col-lg-2 col-form-label fw-bold fs-6'
              style={{ whiteSpace: 'nowrap' }}
            >
              Beskrivelse:
            </label>
            <div className='col-lg-8 fv-row'>
              <Field
                as='textarea'
                name='description'
                rows={3}
                className={clsx(
                  'form-control form-control-lg form-control-solid mb-2 min-h-125px',
                  {
                    'is-invalid': touched.description && errors.description,
                  }
                )}
                placeholder='Skriv en beskrivelse av kampanjen'
              />
              <span className='text-muted'>Du kan redigere det senere om du ønsker</span>
            </div>
          </div>
          <div className='d-flex justify-content-end mt-3'>
            <Button variant='primary' onClick={submitForm}>
              Gå videre
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default React.memo(RecruitmentInfoForm)
