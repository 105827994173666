import React, { useEffect } from 'react'
import { ICampaign, IRecruiter } from '../../../campaign/CampaignTable'
import { ICampaignProps } from '../../../campaign/modal/creation/CampaignCreationModal'
import RecruitmentFacebookForm, { IFacebookPreview } from './RecruitmentFacebookForm'
import RecruitmentInfoForm from './RecruitmentInfoForm'
import RecruitmentLandingPageForm, { ILandingPageColours } from './RecruitmentLandingPageForm'
import CampaignRecruiterForm from './RecruitmentRecruiterForm'
import CampaignSendForm from './RecruitmentSendForm'
import RecruitmentShareInternallyForm from './RecruitmentShareInternallyForm'

interface CampaignCreationModalProps {
  setShowModal: (showModal: boolean) => void
  initialValues: () => ICampaignProps
  mostRecentCampaign: ICampaign | undefined
  campaignId?: string
  recruiters: IRecruiter[]
  handleInfo: (values: ICampaignProps) => Promise<void>
  handleUpload: (recruiters: { name: string; email: string }[]) => Promise<void>
  handleFacebookPreview: (
    facebook_preview: IFacebookPreview,
    useFacebookColor?: boolean
  ) => Promise<void>

  handleSendtoRecruiters: (recruiters: IRecruiter[]) => Promise<void>
  handleLandingpage: (colors: ILandingPageColours) => Promise<void>
  handleStageChange: (stage: string) => Promise<void>
}

export type ICampaignCreationStage =
  | 'info'
  | 'shareInternally'
  | 'recruiters'
  | 'facebook'
  | 'landingpage'
  | 'send'
  | 'complete'

const RecruitmentCreationModal: React.FC<CampaignCreationModalProps> = ({
  initialValues,
  handleInfo,
  mostRecentCampaign,
  handleUpload,

  campaignId,
  recruiters,
  handleSendtoRecruiters,
  handleFacebookPreview,
  handleLandingpage,
  handleStageChange,
}) => {
  const [stage, setStage] = React.useState<ICampaignCreationStage>(
    mostRecentCampaign?.stage || 'shareInternally'
  )
  const [infoValues, setInfoValues] = React.useState<ICampaignProps>(initialValues())
  const [primaryColour, setPrimaryColour] = React.useState('')

  const getHeader = () => {
    switch (stage) {
      case 'info':
        return 'Navn og beskrivelse på rekrutteringskampanjen'
      case 'shareInternally':
        return 'Informer alle tillitsvalgte i klubben om Støtte og rekrutteringsverktøyet'
      case 'recruiters':
        return 'Legg til rekruttererne'
      case 'facebook':
        return 'Tilpass tekst og farger på SOME-poster'
      /*       case 'flyer':
        return 'Tilpass QR-kodesiden' */
      case 'landingpage':
        return 'Tilpass landingssiden'
      case 'send':
        return 'Start rekrutteringsperioden ved å sende ut materiell til rekruttererne'
      default:
        return ''
    }
  }

  useEffect(() => {
    const updateStageInDB = async () => {
      await handleStageChange(stage)
    }
    updateStageInDB()
  }, [stage])

  return (
    <div style={{ maxWidth: '800px' }}>
      <div>
        <h3 className='fw-bolder my-2'>{getHeader()} </h3>
        {stage === 'shareInternally' && (
          <RecruitmentShareInternallyForm
            submitCallback={async () => {
              setStage('info')
            }}
          />
        )}
        {stage === 'info' && (
          <RecruitmentInfoForm
            initialValues={initialValues}
            submitCallback={async (v) => {
              setInfoValues(v)
              await handleInfo(infoValues)
              setStage('facebook')
            }}
          />
        )}
        {stage === 'recruiters' && (
          <CampaignRecruiterForm
            submitForm={() => {
              setStage('landingpage')
            }}
            handleUpload={handleUpload}
            stage='new'
          />
        )}
        {stage === 'facebook' && (
          <RecruitmentFacebookForm
            submitForm={async (facebook_preview) => {
              await handleFacebookPreview(facebook_preview)
              setPrimaryColour(facebook_preview.primaryColour)
              setStage('recruiters')
            }}
            defaultValues={mostRecentCampaign?.facebook_preview}
          />
        )}
        {/*     {stage === 'flyer' && (
          <CampaignFlyerForm
            submitForm={() => {
              setStage('recruiters')
            }}
            handleSave={async (doc) => {
              await handleUploadFlyer(doc)
            }}
            campaignId={campaignId || ''}
            hasRecruiters={!!recruiters?.length}
          />
        )} */}
        {stage === 'landingpage' && (
          <RecruitmentLandingPageForm
            submitForm={async (colors) => {
              await handleLandingpage(colors)
              setStage('send')
            }}
            campaignId={campaignId || ''}
            initColors={() =>
              mostRecentCampaign?.landingpage || {
                backgroundColour: primaryColour || '',
                textColour: '#FFFFFF',
                elementBackgroundColour: '#FFFFFF',
                elementTextColour: '#1f2d54',
                logoColour: '#FFFFFF',
              }
            }
          />
        )}
        {stage === 'send' && (
          /* recruiters?.length && */ <CampaignSendForm
            submitForm={async (sendRecruiters) => {
              await handleSendtoRecruiters(sendRecruiters)

              setStage('complete')
            }}
            recruiters={recruiters || []}
          />
        )}
      </div>
    </div>
  )
}

export default React.memo(RecruitmentCreationModal)
