import clsx from 'clsx'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { Button } from 'react-bootstrap'
import * as xlsx from 'xlsx'
import * as Yup from 'yup'

type Props = {
  submitForm: () => void
  handleUpload: (
    recruiters: { name: string; email: string }[],
    closeDialog: boolean
  ) => Promise<void>
  stage: 'new' | 'edit'
}

const RecruitmentRecruiterForm: React.FC<Props> = ({ handleUpload, submitForm, stage }) => {
  const memberSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required(),
    email: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').email().required(),
  })
  const [recruiters, setRecruiters] = React.useState<{ name: string; email: string }[]>([])
  const [mode, setMode] = React.useState<'upload' | 'create' | undefined>(undefined)

  const label = () => {
    if (stage === 'edit') {
      if (mode === 'create') return 'Lagre'
      return 'Last opp'
    }
    if (recruiters.length) return 'Last opp og gå videre'
    return 'Gå videre'
  }
  return (
    <div>
      {' '}
      <span className='mb4'>
        Rekruttering kan gjøres via enkeltpersoner eller grupper. Vi anbefaler å opprette grupper,
        som Gutter 2015 eller Gutter 2016, med kjente gruppenavn og en kontaktperson for hver
        gruppe.
        <br />
        <br />
        Kontaktpersonen videreformidler rekrutteringsverktøyet og følger opp sin gruppe, mens du
        følger opp kontaktpersonen. Alternativt kan enkeltpersoner legges inn som rekrutterere med
        deres private e-post, og du følger dem opp individuelt. Grupper eller enkeltpersoner kan
        legges til via Excel eller manuelt.
      </span>
      <div style={{ display: 'flex', gap: 5 }} className='mt-4'>
        <button
          onClick={() => setMode('upload')}
          className={`btn ${mode === 'upload' ? 'btn-primary' : 'btn-light'}`}
        >
          Fra regneark
        </button>
        <button
          onClick={() => setMode('create')}
          className={`btn ${mode === 'create' ? 'btn-primary' : 'btn-light'}`}
        >
          Manuelt
        </button>
      </div>
      <br />
      {mode === 'upload' && (
        <div>
          <span className='text-muted'>
            Last ned excel-malen her og legg inn etterspurt info. Deretter lagrer du filen lokalt pả
            maskinen din og laster den opp igjen her ved à trykke pà «Velg fil» under.
          </span>{' '}
          <br />
          <a
            href='https://s3.eu-north-1.amazonaws.com/org.stotte.no/assets/Mal+kampanje.xlsx'
            download
          >
            Last ned excel mal her
          </a>
          <br />
          <br />
          <span className='text-muted'>
            Du kan når som helst legge til flere deltakere ved å laste opp et nytt regneark i
            kampanje oversikten.
          </span>
          <br />
          <br />
          <br />
          <input
            type='file'
            name='sheet'
            accept='.csv, .xlsx'
            onInput={(event) => {
              const input = event.target as HTMLInputElement
              if (!input.files) return
              const reader = new FileReader()
              reader.readAsArrayBuffer(input.files[0])
              return new Promise((resolve, reject) => {
                reader.onload = (e) => {
                  if (e.target) {
                    const data = e.target.result
                    const workbook = xlsx.read(data, {
                      type: 'file',
                      codepage: 65001,
                    })
                    const result = {}
                    workbook.SheetNames.forEach((sheetName) => {
                      const roa = xlsx.utils.sheet_to_json<any>(workbook.Sheets[sheetName], {
                        header: 1,
                      })
                      if (roa.length > 0) {
                        const newRecruiters = roa
                          .map((row) => ({
                            name: row[0],
                            email: row[1],
                          }))
                          .filter((recruiter) => recruiter.name && recruiter.email) // Filter out invalid entries
                          .filter((recruiter) => recruiter.name !== 'Navn') // Filter out invalid entries
                          .filter(
                            (recruiter) =>
                              !recruiters.find((r) => r.name === recruiter.name) &&
                              !recruiters.find((r) => r.email === recruiter.email)
                          ) // Filter out duplicates in existing recruiters
                        setRecruiters([...recruiters, ...newRecruiters])
                      }
                    })

                    resolve(result)
                  }
                  reject('Failed to read file')
                }
              })
            }}
          />
          <br /> <br />
          {recruiters.length ? (
            <div>
              <h5>Rekrutterere : {recruiters.length}</h5>
            </div>
          ) : null}
        </div>
      )}
      {mode === 'create' && (
        <div>
          <div>
            <span className='text-muted'>
              Her oppretter du rekrutterere manuelt ved a legge inn navn pà gruppen eller
              enkeltnavn, og da epost som kontaktpunkt for gruppen eller enkeltpersonens egen epost.
            </span>
          </div>
          <br />
          <Formik
            initialValues={{ name: '', email: '' }}
            validationSchema={memberSchema}
            onSubmit={async (values) => {
              await handleUpload([{ name: values.name, email: values.email }], false)
            }}
          >
            {({ errors, touched, values, submitForm, resetForm }) => (
              <Form>
                <div className='row mb-3 '>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>Navn:</label>
                  <div className='col-lg-9 fv-row'>
                    <Field
                      style={{ borderRadius: '8px' }}
                      type='text'
                      name='name'
                      className={clsx(
                        'form-control form-control-lg form-control-solid inputClass',
                        {
                          'is-invalid': touched.name && errors.name,
                        }
                      )}
                      placeholder='Angi navn på rekutterer'
                    />
                    <ErrorMessage name='name' component='div' />
                  </div>
                </div>
                <div className='row mb-3 mt-7'>
                  <label className='col-lg-3 col-form-label fw-bold fs-6'>E-post:</label>
                  <div className='col-lg-9 fv-row'>
                    <Field
                      style={{ borderRadius: '8px' }}
                      type='text'
                      name='email'
                      className={clsx(
                        'form-control form-control-lg form-control-solid inputClass',
                        {
                          'is-invalid': touched.email && errors.email,
                        }
                      )}
                      placeholder='Angi e-post på deltager'
                    />
                    <ErrorMessage name='name' component='div' />
                  </div>
                </div>
                <br />
                <button
                  type='button'
                  style={{ float: 'right' }}
                  disabled={!!errors.name || !!errors.email || !values.name || !values.email}
                  onClick={async () => {
                    await submitForm()
                    resetForm()
                    const newRecruiters = [
                      ...recruiters,
                      { name: values.name, email: values.email },
                    ]
                      .filter((recruiter) => recruiter.name && recruiter.email) // Filter out invalid entries
                      .filter((recruiter) => recruiter.name !== 'Navn') // Filter out invalid entries
                      .filter(
                        (recruiter) =>
                          !recruiters.find((r) => r.name === recruiter.name) &&
                          !recruiters.find((r) => r.email === recruiter.email)
                      ) // Filter out duplicates in existing recruiters
                    setRecruiters(newRecruiters)
                  }}
                  className='btn btn-primary'
                >
                  Legg til
                </button>
              </Form>
            )}
          </Formik>
          <br /> <br />
        </div>
      )}
      <div className='d-flex justify-content-end mt-3'>
        <Button
          disabled={!recruiters.length}
          variant='primary'
          onClick={async () => {
            if (recruiters.length) await handleUpload(recruiters, true)
            submitForm()
            setRecruiters([])
          }}
        >
          {label()}
        </Button>
      </div>
    </div>
  )
}

export default RecruitmentRecruiterForm
