import * as Sentry from '@sentry/react'
import { FC, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import LoadingContext from '../../../_metronic/layout/core/Loading'
import { useAuth } from '../../modules/auth'
import { handleGetRequest, handlePatchRequest, handlePostRequest } from '../../services'
import { ICampaign, IRecruiter } from '../campaign/CampaignTable'
import { StudioUtils } from '../Marketing/studio/StudioUtils'
import RecruitmentCreationModal from './modal/creation/RecruitmentCreationModal'
import RecruitmentPage from './RecruitmentPage'

const RecruitmentMain: FC = () => {
  const { currentUser } = useAuth()
  const { setLoading } = useContext(LoadingContext)
  const [showCreateCampaign, setShowCreateCampaign] = useState(false)
  const [hasAttemptedToFetchRecruitmentCampaign, setHasAttemptedToFetchRecruitmentCampaign] =
    useState(false)

  const [mostRecentCampaign, setMostRecentCampaign] = useState<ICampaign | undefined>()
  const [mostRecentCampaignID, setMostRecentCampaignID] = useState<string | undefined>()
  const [stage, setStage] = useState<string | undefined>()

  const [recruiters, setRecruiters] = useState<IRecruiter[]>([])

  useEffect(() => {
    // Lookup campaign with isRecruitmentCampaign true
    const fetchRecruitmentCampaign = async () => {
      try {
        const campaign = await handleGetRequest('/campaign/recruitment')(() => {})
        setStage(campaign.data.stage)
        setMostRecentCampaignID(campaign.data._id)

        if (campaign.data.stage !== 'complete') {
          setShowCreateCampaign(true)
          setMostRecentCampaign(campaign.data)
        }
      } catch (error) {
        // Do nothing
      }
      setHasAttemptedToFetchRecruitmentCampaign(true)
    }
    fetchRecruitmentCampaign()
  }, [])

  const handleInitialCreation = async () => {
    setLoading(true)
    const data: Partial<ICampaign> = {
      organisation_id: currentUser?.user?.organisation_id?._id,
      status: 'active',
      title: `Støtt ${currentUser?.user?.organisation_id?.org_name}`,
      short_description: `Bli en viktig støttespiller for ${currentUser?.user?.organisation_id?.org_name} gjennom mikrodonasjoner. Du velger selv hvilket formål du vil støtte. Finn hvor du vil registrere din støtte under.`,
      isRecruitmentCampaign: true,
      stage: 'shareInternally',
      flyer: {
        header: StudioUtils.encodeText(`Støtt ${currentUser?.user?.organisation_id?.org_name}`),
        subheader: StudioUtils.encodeText(`Støtt ${currentUser?.user?.organisation_id?.org_name}!`),
      },
    }
    const campaign = await handlePostRequest('/campaign/', data)(setLoading)

    if (!campaign.data) {
      setShowCreateCampaign(false)
      toast.error('Kampanjeopprettelse mislyktes, vennligst ta kontakt med support')
      Sentry.captureMessage('Campaign creation failed', { extra: { campaign } })
    } else {
      setMostRecentCampaign(campaign.data)
      setMostRecentCampaignID(campaign.data._id)
    }
  }

  const recruitmentCreation = (
    <div className='card campaign-info d-flex flex-column align-items-center justify-content-center text-center py-5'>
      {!showCreateCampaign && (
        <>
          <h1 className='h1 mb-6'>Sjekk verktøyene du har for rekruttering!</h1>

          <div className='campaign-image'>
            <img
              src='/media/recruitment-landing.png'
              alt='Campaign info'
              className='h-auto'
              style={{ maxHeight: '540px', maxWidth: '1000px' }}
            />
          </div>
          <div
            className='p-6 d-flex flex-column gap-4 align-items-center'
            style={{ maxWidth: '800px' }}
          >
            <p className='mb-0'>
              Vi anbefaler på det sterkeste at du går gjennom dette oppsettet steg for steg slik at
              medlemmene/foreldrene blir med og rekrutterer støttespillere!
              <br />
              <br />
              Vi anbefaler også at det kjøres rekrutteringsrunder flere ganger årlig. Oppsettet her
              gjør du kun en gang, men for hver rekrutteringsrunde dere skal ha har du mulighet for
              å redigere innholdet i oppsettet du lager første gang.
              <br />
              <br />
              Som du vil se underveis i oppsettet vil du mest trolig ikke fullføre det med en gang,
              men du tar da opp prosessen fra der du slapp når du neste gang velger Rekruttering i
              menyen til venstre.
            </p>
            <button
              onClick={async () => {
                await handleInitialCreation()
                setShowCreateCampaign(true)
              }}
              className='btn btn-primary'
            >
              Kom i gang!
            </button>
          </div>
        </>
      )}

      {showCreateCampaign && (
        <RecruitmentCreationModal
          initialValues={() => {
            return {
              name: `Støtt ${currentUser?.user?.organisation_id?.org_name}`,
              description: `Bli en viktig støttespiller for ${currentUser?.user?.organisation_id?.org_name} gjennom mikrodonasjoner. Du velger selv hvilket formål du vil støtte. Finn hvor du vil registrere din støtte under.`,
              level: 'organisation',
              goal: '',
              unit: '',
            }
          }}
          setShowModal={setShowCreateCampaign}
          mostRecentCampaign={mostRecentCampaign}
          recruiters={recruiters}
          handleInfo={async (modalData) => {
            const campaign = await handlePatchRequest(`/campaign/${mostRecentCampaignID}`, {
              title: modalData.name,
              short_description: modalData.description,
              flyer: {
                header: StudioUtils.encodeText(modalData.name),
                subheader: StudioUtils.encodeText(modalData.name),
              },
            })(setLoading)
            setMostRecentCampaign(campaign.data)
          }}
          handleUpload={async (recruiters) => {
            if (!mostRecentCampaignID) return
            await handlePostRequest(`/campaign/add_users/`, {
              campaign_id: mostRecentCampaignID,
              recruiters: JSON.stringify(recruiters),
            })(setLoading)
            const campaign = await handleGetRequest(`/campaign/single/${mostRecentCampaignID}`)(
              setLoading
            )
            setMostRecentCampaign(campaign.data)
            setRecruiters(campaign.data.recruiters)
          }}
          campaignId={mostRecentCampaignID}
          handleStageChange={async (stage) => {
            const campaign = await handlePatchRequest(`/campaign/${mostRecentCampaignID}`, {
              stage,
            })(setLoading)
            setMostRecentCampaign(campaign.data)
            setStage(stage)
          }}
          /*           handleUploadFlyer={async (doc) => {
            if (!mostRecentCampaignID) return

            const campaign = await handlePatchRequest(`/campaign/${mostRecentCampaignID}`, {
              flyer: {
                header: StudioUtils.encodeText(doc.topHeader),
                subheader: StudioUtils.encodeText(doc.bottomHeader),
              },
            })(setLoading)
            setMostRecentCampaign(campaign.data)
          }} */
          handleSendtoRecruiters={async (selectedSendRecruiters) => {
            if (selectedSendRecruiters.length > 0) {
              await handlePostRequest(`/organisation_user/send-campaign-email`, {
                campaignId: mostRecentCampaignID,
                recruiter_short_user_ids: selectedSendRecruiters.map((r) => r.short_user_id),
                organisation_name: currentUser?.user?.organisation_id?.org_name,
              })(setLoading)
              setLoading(false)
            }
          }}
          handleFacebookPreview={async (facebook_preview, useFacebookColor) => {
            if (!mostRecentCampaignID) return
            const campaign = await handlePatchRequest(`/campaign/${mostRecentCampaignID}`, {
              facebook_preview,
              useFacebookColor,
              landingpage: {
                backgroundColour: facebook_preview.primaryColour,
                textColour: '#FFFFFF',
                elementBackgroundColour: '#FFFFFF',
                elementTextColour: '#1f2d54',
                logoColour: '#FFFFFF',
              },
            })(setLoading)
            setMostRecentCampaign(campaign.data)
          }}
          handleLandingpage={async (colors) => {
            if (!mostRecentCampaignID) return
            const campaign = await handlePatchRequest(`/campaign/${mostRecentCampaignID}`, {
              landingpage: colors,
            })(setLoading)
            setMostRecentCampaign(campaign.data)
          }}
        />
      )}
    </div>
  )
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {mostRecentCampaignID && stage === 'complete' ? (
        <RecruitmentPage id={mostRecentCampaignID} />
      ) : hasAttemptedToFetchRecruitmentCampaign ? (
        recruitmentCreation
      ) : null}
    </>
  )
}

export { RecruitmentMain }
