import React from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { ColorPopoverPicker } from '../../../../components/ColorPopoverPicker'
import { useAuth } from '../../../../modules/auth'
import { facebookBGImage } from '../../../campaign/modal/creation/FacbookBGImage'

export interface IFacebookPreview {
  primaryColour: string
  secondaryColour: string
  textColour: string
  header: string
}
type Props = {
  submitForm: ({ primaryColour, secondaryColour, textColour, header }: IFacebookPreview) => void
  defaultValues?: IFacebookPreview
  submitText?: string
}

const RecruitmentFacebookForm: React.FC<Props> = ({ submitForm, defaultValues, submitText }) => {
  const { currentUser } = useAuth()

  const [primaryColour, setPrimaryColour] = React.useState(
    defaultValues?.primaryColour || '#1f2d54'
  )
  const [secondaryColour, setSecondaryColour] = React.useState(
    defaultValues?.secondaryColour || '#f05a28'
  )
  const [textColour, setTextColour] = React.useState(defaultValues?.textColour || '#ffffff')
  const [headerText, setHeaderText] = React.useState(
    defaultValues?.header || 'Sammen gjør vi en stor forskjell!'
  )

  const [showCustomHeaderControls, setShowCustomHeaderControls] = React.useState(false)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <span className='text-muted'>
          Dette er verktøyet du ønsker medlemmer og foreldre skal legge ut på sine SOME-kanaler. Du
          kan velge klubbfargene deres, og legge inn ønsket tekst i overskriften på SOME-posten. Du
          kan redigere dette også senere.
        </span>
      </div>
      <br />
      <div
        style={{
          zoom: '0.6',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '1200px',
          height: '630px',
          color: textColour,
          textAlign: 'left',
          fontFamily: 'Dosis',
          fontWeight: 'semibold',
          padding: '50px 77px',
          whiteSpace: 'pre',
          backgroundColor: primaryColour,
          backgroundImage: facebookBGImage,
          backgroundSize: '1200px 630px',
          alignSelf: 'center',
        }}
      >
        <h2
          style={{
            color: textColour,
            fontSize: '35px',
            display: 'flex',
            marginBottom: '11px',
            fontFamily: 'Roboto Condensed',
            fontWeight: 400,
          }}
        >
          {headerText}
        </h2>
        <h1
          style={{
            color: secondaryColour,
            fontSize: '65px',
            marginBottom: '-5px',
            lineHeight: '79px',
            whiteSpace: 'pre',
          }}
        >
          Støtt <span style={{ color: textColour }}>{'<NAVN>'}</span> og{' '}
          <span style={{ color: textColour }}>{currentUser?.user?.organisation_id?.org_name}</span>
        </h1>
        <h1
          style={{
            color: secondaryColour,
            fontSize: '65px',
            marginBottom: '30px',
            lineHeight: '79px',
            whiteSpace: 'pre',
          }}
        >
          med gjentakende mikrodonasjoner!
        </h1>
        <p
          style={{
            fontSize: '33px',
            marginBottom: '0px',
            maxWidth: '485px',
            lineHeight: '41px',
            fontFamily: 'Roboto Condensed',
            fontWeight: 400,
          }}
        >
          Registrer deg i appen Støtte og bli
        </p>
        <p
          style={{
            fontSize: '33px',
            marginBottom: '56px',
            maxWidth: '485px',
            lineHeight: '41px',
            fontFamily: 'Roboto Condensed',
            fontWeight: 400,
          }}
        >
          bli med på den nye digitale dugnaden.
        </p>
        <div
          style={{
            backgroundColor: secondaryColour,
            color: textColour,
            fontSize: '35px',
            padding: '15px 30px',
            borderRadius: '8px',
            border: 'none',
            display: 'flex',
            whiteSpace: 'pre',
          }}
        >
          Klikk her eller bruk koden{' '}
          <span style={{ color: primaryColour, fontWeight: 'bold' }}>{'<KODE>'}</span>
        </div>
      </div>
      <br />
      <br />
      <div className='fb-control-group'>
        <div className='primary-fb-colour'>
          Primærfarge
          <ColorPopoverPicker color={primaryColour} onChange={setPrimaryColour} text={false} />
        </div>
        <div className='secondary-fb-colour'>
          Kontrastfarge
          <ColorPopoverPicker
            color={secondaryColour}
            onChange={(val) => {
              setSecondaryColour(val)
            }}
            text={false}
          />
        </div>
        <div className='text-fb-colour'>
          Tekstfarge
          <ColorPopoverPicker color={textColour} onChange={setTextColour} text />
        </div>
        <div className='text-fb'>
          Overskrift
          <Dropdown bsPrefix='dropdown' className='filter-custom'>
            <Dropdown.Toggle
              bsPrefix='dropdown'
              className='btn btn-light dropdown-toggle btn-sm  '
              id='filter-dates'
              style={{ width: '100%' }}
            >
              {showCustomHeaderControls ? 'Egendefinert tekst' : headerText}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setShowCustomHeaderControls(false)
                  setHeaderText('Sammen gjør vi en stor forskjell!')
                }}
              >
                Sammen gjør vi en stor forskjell!
              </Dropdown.Item>{' '}
              <Dropdown.Item
                onClick={() => {
                  setShowCustomHeaderControls(false)
                  setHeaderText('Vårt alternativ til salgsdugnader')
                }}
              >
                Vårt alternativ til salgsdugnader
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowCustomHeaderControls(true)}>
                Egendefinert tekst
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {showCustomHeaderControls && (
            <input
              type='text'
              className='form-control form-control-sm mt-2'
              value={headerText}
              onChange={(e) => setHeaderText(e.target.value)}
            />
          )}
        </div>
      </div>
      <div className='d-flex justify-content-end mt-3'>
        <Button
          variant='primary'
          onClick={() => {
            submitForm({
              primaryColour,
              secondaryColour,
              textColour,
              header: headerText,
            })
          }}
        >
          {submitText || 'Gå videre'}
        </Button>
      </div>
    </div>
  )
}

export default RecruitmentFacebookForm
