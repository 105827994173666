import { cloneDeep } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Tour from 'reactour'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import Page404 from '../../_metronic/layout/components/404'
import { PageLink, PageTitle } from '../../_metronic/layout/core'
import TourContext from '../../_metronic/layout/core/tour'
import { setCurrentUser, useAuth } from '../modules/auth'
import FormalSingle from '../pages/Formal/FomalSingle'
import FormalAdd from '../pages/Formal/FormalAdd'
import FormalEdit from '../pages/Formal/FormalEdit'
import Marketing from '../pages/Marketing'
import { MarketingMain } from '../pages/Marketing/MarketingMain'
import SupportBranding from '../pages/Marketing/SupportBranding'
import MarketingVideos from '../pages/Marketing/Videos'
import StudioPage from '../pages/Marketing/studio/StudioPage'
import { MenuTestPage } from '../pages/MenuTestPage'
import { CampaignMain } from '../pages/campaign/CampaignMain'
import CampaignSingle from '../pages/campaign/CampaignSingle'
import { DashboardPage } from '../pages/dashboard/DashboardPage'
import { HomePage } from '../pages/dashboard/HomePage'
import VippsModal from '../pages/dashboard/_modals/DashboardVippsModal'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import { PaymentsMiansp } from '../pages/payments/PaymentsMiansp'
import { GoalMain } from '../pages/purpose/GoalMain'
import { RecruitmentMain } from '../pages/recruitment/RecruitmentMain'
import { ReportMain } from '../pages/reporting/ReportingMain'
import { StatisticMain } from '../pages/statistic/StatisticMain'
import SettingsMiansp from '../pages/settings/SettingsMiansp'
import { SportsMain } from '../pages/sports/SportsMain'
import { SupportPlayerMiansp } from '../pages/support_player/SupportPlayerMiansp'
import { UserSetttingMain } from '../pages/user/UserSetttingMain'
import UsersorgMiansp from '../pages/usersorg/UsersorgMiansp'
import { handlePatchRequestWithoutMessage } from '../services'

function PrivateRoutes() {
  // ...

  // const {currentUser} = useAuth()

  const steps = [
    {
      selector: '#mer',
      content: () => (
        <div>
          <h3>Velkommen!</h3>
          <p>
            Vi må starte med å registrere organisasjonen i Vipps. Om organisasjonen ikke er
            registrert i Vipps kan dere ikke motta støtte.{' '}
          </p>
          <p>
            OBS: Det koster ikke noe å registrere organisasjonen og det er ikke noen bindingstid.{' '}
          </p>
          <p>
            Å registrere organisasjonen tar bare 1 min. Klikk{' '}
            <span
              className='text-primary'
              style={{ cursor: 'pointer' }}
              onClick={() => vippsModalTrue()}
            >
              Vipps onboarding
            </span>{' '}
            knappen for å gå videre.{' '}
          </p>
        </div>
      ),
    },
    {
      selector: '#orgSettings',
      content: () => (
        <div>
          <h3>Last opp logo</h3>
          <p>Om du ikke har en logo i systemet allerede så kan du laste den opp selv.</p>
          <p>
            Logoen vises i appen. For å få flest mulig til å støtte organisasjon og formål er det
            viktig at støttespillerne lett gjenkjenner organisasjonens logo. Her kan du også
            komplettere informasjon om organisasjonen. Det vil selvsagt også vises i appen.{' '}
          </p>
          <p>Husk å laste opp logo i jpeg eller png format.</p>
        </div>
      ),
    },
    {
      selector: '#orgUsers',
      content: () => (
        <div>
          <h3>Brukertilgang</h3>
          <p>Her gir du andre i organisasjonen din tilgang til plattformen.</p>
          <p>Du velger selv hvilken tilgang du skal gi brukeren: Admin eller Manager. </p>
          <p>Admin: gir full tilgang til alle enheter i klubben og systeminnstillinger.</p>
          <p>Manager: gir kun tilgang til en (eller flere )enheter.</p>
          <p>Managere får ikke tilgang til innstillinger eller brukeradministrasjon.</p>
          <p>Du kan selvsagt endre tilgangsrettighetene senere. </p>
        </div>
      ),
    },
    {
      selector: '.select-btn',
      content: () => (
        <div>
          <h3> Velg enhet</h3>
          <p>
            Fra nedtrekksmenyen velger du hvilken enhet du skal administrere eller lage formål til.
          </p>
        </div>
      ),
    },
    {
      selector: '#marketing',
      content: () => (
        <div>
          <h3>Markedsføring</h3>
          <p>Vi har laget en enkel guide som hjelper dere med markedsføringen av konseptet.</p>
          <p>
            Du kan kopiere tekster og video-lenker og spre det glade budskapet i det mediet hvor du
            vanligvis kommuniserer med medlemmene. Alt er allerede klargjort slik at dere slipper å
            finne opp “hjulet” på nytt. Kopier, lim inn og send ut til medlemmene for å skape
            engasjement!
          </p>
          <p>Jo flere dere får med, jo mer penger til organisasjonen.</p>
          <p>
            Klikk deg rundt for å se hvilke muligheter det ligger i denne markedsføringspakken.
            Lykke til!
          </p>
        </div>
      ),
    },
  ]

  const stepsManager = [
    {
      selector: '#ytplayer',
      content: () => (
        <div>
          {currentUser?.user?.user_type === 'manager' ? (
            <>
              <h3>Kom i gang</h3>
              <p>
                Vi har klargjort en video-guide som forklarer hvordan du skal anvende Støtte
                plattform.
              </p>
              <p>Vi anbefaler å se gjennom hele videoen før du tar systemet i bruk.</p>

              {/* <p>Manager rolle gir kun tilgang til en (eller flere enheter).</p>
          <p>Managere får ikke tilgang til innstillinger eller bruker administrasjon.</p>
          <p>Du selvsagt justere rollene fortløpende.</p> */}
            </>
          ) : (
            <>
              <h3>Kom i gang</h3>
              <p>
                Vi har klargjort en video-guide som forklarer hvordan du skal anvende Støtte
                plattform.
              </p>
              <p>Vi anbefaler å se gjennom hele videoen før du tar systemet i bruk.</p>
              <p>Manager rolle gir kun tilgang til en (eller flere enheter).</p>
              <p>Managere får ikke tilgang til innstillinger eller bruker administrasjon.</p>
              <p>Du selvsagt justere rollene fortløpende.</p>
            </>
          )}
        </div>
      ),
    },
    {
      selector: '.select-btn',
      content: () => (
        <div>
          <h3>Velg enhet</h3>

          {currentUser?.user?.user_type === 'manager' ? (
            <>
              <p>
                Fra nedtrekksmenyen velger du hvilken enhet du skal administrere eller lage formål
                til.
              </p>
              <p>
                Dersom du har tilgang til flere enheter kan du enkelt velge riktig enhet herfra.
                Dersom du kun har tilgang til en enhet vil du ikke kunne velge andre enheter.
              </p>
            </>
          ) : (
            <>
              <p>
                Fra nedtrekksmenyen velger du hvilken enhet du skal administrere eller lage formål
                til.
              </p>
              <p>
                Dersom du har tilgang til flere enheter kan du enkelt velge riktig enhet herfra.
                Dersom du kun har tilgang til en enhet vil du ikke kunne velge andre enheter.
              </p>
            </>
          )}
        </div>
      ),
    },
    {
      selector: '#marketing',
      content: () => (
        <div>
          <h3>Markedsføring</h3>
          <p>Vi har laget en enkel guide som hjelper dere med markedsføringen av konseptet.</p>
          <p>
            Her er det bare å kopiere tekster og videolenker til å spre det glade budskapet i det
            mediet dere kommuniserer med medlemmene gjennom. Alt er allerede klargjort slik at dere
            slipper å finne opp “hjulet” på nytt. Kopier, lim in og send ut til medlemmene for å
            skape engasjement!
          </p>
          <p>Jo fler dere får med, jo mer penger til organisasjonen</p>
          <p>Klikk deg rundt å se hvilken muligheter det ligger i denne markedsføringpakken.</p>
          <p>Lykke til!</p>
        </div>
      ),
    },
    {
      selector: '.support',
      content: () => (
        <div>
          <h3>Støtte support</h3>
          <p>
            Oppdager du feil i systemet eller trenger hjelp med noe kan du opprette en support
            henvendelse gjennom support skjemaet. Team Støtte står klar til å hjelpe!
          </p>
        </div>
      ),
    },
  ]

  const CompaniesdMianRoutsBreadCrumbs: Array<PageLink> = [
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const [showVippsModal, setShowVippsModal] = useState(false)
  const { tour, setTour } = useContext(TourContext)
  const { currentUser } = useAuth()

  let vippsModalTrue = () => {
    setShowVippsModal(true)
    setTour(false)
  }

  const [tourSteps, setTourSteps] = useState(steps)

  const handleClose = async () => {
    setTour(false)
    const formData = new FormData()
    formData.append('tour_step', 'true')

    const { success } = await handlePatchRequestWithoutMessage(
      `/organisation_user/update_profile/${currentUser?.user?._id}`,
      formData
    )(() => {
      // console.log('success')
    })
    if (success) {
      const user = cloneDeep(currentUser)
      user.user.tour_step = true
      setCurrentUser(user)
    }
  }

  useEffect(() => {
    if (
      currentUser?.user?.organisation_id?.msn_status !== 'pending' &&
      currentUser?.user?.user_type !== 'manager'
    ) {
      const tSteps = cloneDeep(steps)
      tSteps.shift()

      setTourSteps(tSteps)
    }
    if (currentUser?.user?.user_type === 'manager') {
      const tSteps = cloneDeep(stepsManager)

      setTourSteps(tSteps)
    }

    if (currentUser && !currentUser?.user?.tour_step) {
      setTimeout(() => {
        setTour(true)
        localStorage.setItem('tour', 'true')
      }, 1000)
    }
  }, [currentUser])

  return (
    <>
      <Tour
        steps={tourSteps}
        isOpen={tour}
        onRequestClose={handleClose}
        // add bootstrap styles

        accentColor='rgb(255, 91, 36)'
        className='reactour__helper'
        rounded={5}
      />
      {showVippsModal ? (
        <VippsModal
          show={showVippsModal}
          handleClose={() => {
            setShowVippsModal(false)
            setTour(true)
          }}
        />
      ) : (
        ''
      )}

      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='dashboard' element={<DashboardPage />} />
          <Route path='home' element={<HomePage />} />
          <Route path='builder' element={<BuilderPageWrapper />} />
          <Route path='menu-test' element={<MenuTestPage />} />
          <Route path='marketing' element={<Marketing />} />
          <Route path='marketing/videos' element={<MarketingVideos />} />

          <Route path='marketing/branding' element={<SupportBranding />} />
          <Route path='marketing/studio' element={<StudioPage />} />
          <Route path='marketing/studio/campaign/:id' element={<StudioPage />} />
          <Route path='/formalAdd' element={<FormalAdd />} />
          <Route path='/formalSingle/:id' element={<FormalSingle />} />
          <Route path='/campaignSingle/:id' element={<CampaignSingle />} />
          <Route path='/formalEdit/:id' element={<FormalEdit />} />
          {/* Lazy Modules */}
          <Route path='usersettting' element={<UserSetttingMain />} />
          {/* Add a common prefix to the paths of all org routes */}
          <Route path='/org/*'>
            <Route
              path='goals'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs}>Mine selskap</PageTitle>
                  <GoalMain />
                </>
              }
            />
            <Route
              path='support-player'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs}>Regnskap</PageTitle>
                  <SupportPlayerMiansp />
                </>
              }
            />{' '}
            <Route
              path='sports'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs}>Regnskap</PageTitle>
                  <SportsMain />
                </>
              }
            />
            <Route
              path='payments'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs}>Signering</PageTitle>
                  <PaymentsMiansp />
                </>
              }
            />
            <Route
              path='users'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs} />
                  <UsersorgMiansp />
                </>
              }
            />
            <Route
              path='reporting'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs} />
                  <ReportMain />
                </>
              }
            />
            <Route
              path='editStatisticPage'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs} />
                  <StatisticMain />
                </>
              }
            />
            <Route
              path='campaign'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs} />
                  <CampaignMain />
                </>
              }
            />{' '}
            <Route
              path='recruitment'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs} />
                  <RecruitmentMain />
                </>
              }
            />
            <Route
              path='settings'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs}>Aksjeeierbok</PageTitle>
                  <SettingsMiansp />
                </>
              }
            />
            <Route
              path='marketing'
              element={
                <>
                  <PageTitle breadcrumbs={CompaniesdMianRoutsBreadCrumbs}>Aksjeeierbok</PageTitle>
                  <MarketingMain />
                </>
              }
            />
          </Route>
          {/* Page Not Found */}
        </Route>
        <Route path='*' element={<Page404 />} />
      </Routes>
    </>
  )
}

// const SuspensedView: FC = ({children}) => {
//   const baseColor = getCSSVariableValue('--bs-primary')
//   TopBarProgress.config({
//     barColors: {
//       '0': baseColor,
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   })
//   return (
//     <Suspense fallback={<TopBarProgress />}>
//       <DisableSidebar>{children}</DisableSidebar>
//     </Suspense>
//   )
// }

export { PrivateRoutes }
