import React from 'react'

type Props = {
  className: string
}
const EditStatisticPage: React.FC<Props> = () => {
  return (
    <>
      <div className={`card `}>
        <div>
          <div className='card-body h-auto'>
            <div className='d-flex flex-wrap flex-sm-nowrap'>
              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap'>
                  <div className='d-flex flex-row w-100' style={{ height: '100vh' }}>
                    <div
                      className='fs-2 fw-bolder me-1'
                      style={{ color: 'black', flex: '0 0 60%' }}
                    >
                      <iframe
                        src='http://localhost:3000/63c28fa8ada7998ce8126038'
                        width='60%'
                        height='100%'
                        style={{ border: 'none' }}
                        title='Local IFrame'
                      />
                    </div>
                    <div
                      className='fs-2 fw-bolder me-1'
                      style={{ color: 'black', flex: '0 0 40%' }}
                    >
                      <p>Background color:</p>
                      <p>Font color:</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div> </div>
    </>
  )
}

export default EditStatisticPage
