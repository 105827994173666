import React from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from 'react-intl'

type Props = {
  showModalConfirm: boolean
  setShowModalConfirm: (show: boolean) => void
  handleDelete: () => void
}

const RecruitmentRecruiterDeletionModal: React.FC<Props> = ({
  showModalConfirm,
  setShowModalConfirm,
  handleDelete,
}) => {
  const intl = useIntl()

  return (
    <Modal
      show={showModalConfirm}
      onHide={() => setShowModalConfirm(false)}
      size='lg'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className='modal-title'>
            {intl.formatMessage({ id: 'yes.delete.campaign.recruiter' })}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <div className='modal-body'>
        {intl.formatMessage({ id: 'yes.delete.campaign.recruiter.desc' })}

        <br />
        <br />
        <br />
        <button
          type='button'
          className='btn btn-light me-3'
          onClick={() => {
            setShowModalConfirm(false)
          }}
        >
          {intl.formatMessage({ id: 'Cancel' })}
        </button>
        <button
          type='button'
          style={{ float: 'right' }}
          onClick={() => {
            handleDelete()
          }}
          className='btn btn-primary'
        >
          {intl.formatMessage({ id: 'yes.delete' })}
        </button>
      </div>
    </Modal>
  )
}

export default RecruitmentRecruiterDeletionModal
