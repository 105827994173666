/* eslint-disable no-async-promise-executor */
/* eslint-disable class-methods-use-this */
import { Frame } from 'zimjs'
import { IDocStorage } from '../StudioApp'
import { Manifest } from '../StudioManifest'
import { AbstractContainer } from '../containers/AbstractContainer'
import { CenteredText } from '../containers/CenteredText'
import { Graphic } from '../containers/Graphic'
import { EditorImage } from '../containers/Image'

export class CampaignTemplate {
  private width: number

  private height: number

  private parentFrame: Frame

  private header: CenteredText | undefined

  private code: CenteredText | undefined

  private backgroundImage: EditorImage | undefined

  private orgLogo: Graphic | undefined

  private qrCode: Graphic | undefined

  private doc: IDocStorage

  private imgString: string | undefined

  constructor(frame: Frame, width: number, height: number, doc: IDocStorage) {
    this.width = width
    this.height = height
    this.parentFrame = frame
    this.doc = doc
    this.initialize()
    this.addListeners()
  }

  initialize() {
    this.backgroundImage = this.addElement(new EditorImage(this.width, this.height, this.bgImage))
    this.code = this.addElement(
      new CenteredText(0, 0, {
        color: Manifest.colors.blue,
        text: this.doc.code,
        size: 30,
        font: 'Roboto',
      })
    )
    this.header = this.addElement(
      new CenteredText(200, 300, {
        color: this.headerColor,
        text: this.doc.topHeader,
        size: 34,
        font: 'Dosis',
      })
    )

    if (this.doc.orgLogo) {
      this.orgLogo = this.addElement(new Graphic(this.width / 5, this.height / 5, this.doc.orgLogo))
      this.orgLogo.vis(this.doc.displayOrgLogo)
    }

    if (this.doc.qrCode && !this.qrCode) {
      this.qrCode = this.addElement(new Graphic(this.width / 2, this.height / 2, this.doc.qrCode))
    }

    this.updateLayout()
  }

  updateImageLayout() {}

  async updateLayout() {
    return new Promise<void>(async (resolve) => {
      this.backgroundImage!.setImage(this.bgImage)
      this.backgroundImage!.center()

      if (!this.imgString || this.imgString !== this.bgImage) {
        this.imgString = this.bgImage
        this.updateImageLayout()
      }
      if (this.orgLogo) {
        this.orgLogo.pos(this.width / 2 - this.orgLogo.width / 2, -6)
        this.orgLogo.vis(this.doc.displayOrgLogo)
      }

      this.header!.pos(235, 145)

      this.header!.setText(this.doc.topHeader, this.headerColor)
      this.code!.pos(235, 520)
      this.code!.setText(this.doc.code)

      if (this.qrCode) {
        await this.qrCode!.setImageAsync(this.doc.qrCode!)
        this.qrCode!.center()
        this.qrCode!.pos(this.qrCode!.x, this.qrCode!.y - 25)
      }

      resolve()
    })
  }

  addElement<T extends AbstractContainer>(element: T) {
    return element
  }

  addListeners() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.parentFrame.on('DocChange', async (e: any) => {
      this.doc = e.detail as IDocStorage
      await this.updateLayout()
      this.parentFrame?.stage?.update()

      this.parentFrame?.dispatchEvent(new CustomEvent('DocComplete', { detail: this.doc }))
    })
  }

  get bgImage() {
    return this.doc.printVersion ? Manifest.images.campaignNeg : Manifest.images.campaignPos
  }

  get headerColor() {
    return this.doc.printVersion ? Manifest.colors.blue : Manifest.colors.white
  }
}
